import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import '../css/SignIn.css';

function SignIn({ onSignIn }) {
  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        // Send the access token to Google's tokeninfo endpoint
        const verificationResponse = await fetch(
          `https://oauth2.googleapis.com/tokeninfo?access_token=${tokenResponse.access_token}`
        );
        const verificationData = await verificationResponse.json();
        console.log(verificationData)
        // Check if the email is verified
        if (verificationData.email_verified === "true") {
          console.log("User is verified, allowing access to bot.");
          // Notify parent component of successful sign-in
          onSignIn(true);
        } else {
          console.error("Email is not verified, access denied.");
        }
      } catch (error) {
        console.error("Error during token verification:", error);
        onSignIn(false);
      }
    },
    onError: (error) => {
      console.error('Google login failed', error);
      onSignIn(false);
    },
    ux_mode: 'popup',
    text:"continue_with"
    
  });

  return (
    <div className="signin-overlay">
      <div className="signin-container">
        <div className="signin-header">
          <h1>KPOINT Videobook</h1>
          <p>Sign in to access the videobook</p>
        </div>
        <div className="signin-form">
          {/* <button className="signin-btn" >
            Log in with Google
          </button> */}
          <button className="signup-btn" onClick={() => googleLogin()}>Sign in with Google</button>
        </div>
        {/* <div className="stay-logged-out">
          <a href="#">Stay logged out</a>
        </div> */}
      </div>
    </div>
  );
}

export default SignIn;