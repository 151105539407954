import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './js/Home';
import Chatbot from './js/Chatbot';
import SignIn from './js/SignIn';
import { GoogleOAuthProvider } from '@react-oauth/google';
import PulseLoader from 'react-spinners/PulseLoader';
import { useState, useEffect } from 'react';

function App() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);  // Loading state

  // Check if the user is already signed in on page load
  useEffect(() => {
    const storedAccessToken = localStorage.getItem('accessToken');
    if (storedAccessToken) {
      setIsSignedIn(true);
      console.log("already sign in")
    }
    setIsLoading(false);  // Once the check is done, stop loading
  }, []);

  // Handle successful sign-in and store user details
  const handleSignIn = (tokens) => {

    // Store the access token and profile in local storage for persistence
    localStorage.setItem('accessToken', tokens.access_token);
    localStorage.setItem('userProfile', JSON.stringify(tokens.profile)); // Store the profile as a string
    setIsSignedIn(true);
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <PulseLoader size={8} color="#30c484" />
      </div>
    );
  }


  return (
    <div className="App">
      <main>
      <GoogleOAuthProvider clientId='898015710493-a8gpr84qjvbvbd0ru1gmvo69m3b78j8l.apps.googleusercontent.com'>
          {!isSignedIn ? (
            <SignIn onSignIn={handleSignIn} />
          ) : (
            <Chatbot  /> 
          )}
        </GoogleOAuthProvider>
      </main>
    </div>
    // <Router>
    //   <Routes>
    //     <Route path="/" element={<Home />} />
    //     <Route path="/chatbot" element={<Chatbot />} />
    //   </Routes>
    // </Router>
  );
}

export default App;
